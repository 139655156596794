<template>
  <el-dialog
      title="收款编辑"
      width="55%"
      height="55%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
      <el-row>
        <el-col :span="7">
          <el-form-item label="客户名称" prop="companyName">
            <el-input readonly v-model="form.companyName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="课程名" prop="courseName" >
            <el-input readonly v-model="form.courseName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="订单总价" prop="orderPrice">
            <el-input readonly v-model="form.orderPrice"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="收款项目" prop="projectId">
            <el-select v-model="form.projectId" placeholder="收款项目">
              <el-option
                  v-for="item in projectArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="收款方式" prop="paymentId">
            <el-cascader
                v-model="form.paymentId"
                :options="paymentArray"
                :props="{ expandTrigger: 'hover', value:'value',label:'name',children:'children'}"
                @change="paymentChange"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="收款金额" prop="money">
            <el-input v-model="form.money"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="收款时间" prop="createDate">
            <el-date-picker
                style="width:183px"
                v-model="form.createDate"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                type="date"
                placeholder="收款时间"/>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="收款人" prop="payee">
            <el-select v-model="form.payee" placeholder="收款人">
              <el-option
                  v-for="item in payeeArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="21">
          <el-form-item label="备注">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4}"
                placeholder="请输入内容"
                v-model="form.remark">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addProceedsCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "proceeds-edit",
  data() {
    return {
      form: {
        //公司名
        id:this.proceeds.id,
        companyName: this.proceeds.companyName,
        courseName: this.proceeds.courseName,
        orderPrice: this.proceeds.orderPrice,
        projectId: this.proceeds.projectId,
        paymentId: this.proceeds.paymentId,
        createDate:this.proceeds.createDate,
        payee: this.proceeds.payeeId,
        money: this.proceeds.proceedsPrice,
        orderId:this.proceeds.orderId,
        tenantCrop:localStorage.getItem("tenantCrop"),
      },
      rules:{
        companyName: [
          { required: true, message: '请输入客户名', trigger: 'blur' },
        ],
        courseName: [
          { required: true, message: '请输入课程名', trigger: 'blur' },
        ],
        orderPrice: [
          { required: true, message: '请输入订单总价', trigger: 'blur' },
        ],
        projectId: [
          { required: true, message: '请选择收款项目', trigger: 'change' },
        ],
        paymentId: [
          { required: true, message: '请选择收款方式', trigger: 'change' },
        ],
        money: [
          { required: true, message: '请输入收款金额', trigger: 'blur' },
        ],
        createDate: [
          { required: true, message: '请选择收款日期', trigger: 'change' },
        ],
        payee: [
          { required: true, message: '请选择收款人', trigger: 'change' },
        ],

      },
      projectArray:[],
      paymentArray:[],
      payeeArray:[],
      dialogState: this.state,
    }
  },
  created() {
    this.queryProjectIds();
    this.queryPaymentIds();
    this.queryPayeeIds();
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    proceeds:{
      type: Object,
      required: true,
      default(){return{}},
    }
  },
  methods: {
    //检查订单校验
    addProceedsCheck:function (){
      this.$refs['form'].validate((valid) => {
        if (valid){
          this.addProceeds();
        }
      })
    },
    //添加收款
    addProceeds: function () {
      let value="是否添加此收款?"
      this.$confirm(value, '添加收款:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/trainProceeds/updateTrainProceeds",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("添加成功!")
            let self=this;
            setTimeout(function (){self.close()},1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    paymentChange(val){
      this.form.paymentId=val.length===1?val[0]:val[1]
    },
    //查询项目ID
    queryProjectIds(){
      this.$selectUtils.queryProjectsIds(this.$projectsType.proceeds).then(response=>{
        this.projectArray=JSON.parse(response.data.data);
      })
    },
    //查询项目ID
    queryPaymentIds(){
      this.$selectUtils.queryPaymentIds(this.$projectsType.proceeds).then(response=>{
        this.paymentArray=JSON.parse(response.data.data);
      })
    },
    //查询收款人
    queryPayeeIds(){
      this.$selectUtils.queryEmpIds().then(response=>{
        this.payeeArray=JSON.parse(response.data.data);
      })
    },
    close:function (){
      this.$emit("close",false);
    }
  }
}
</script>

<style scoped>

</style>